import React from "react";
import Navbar from "../../components/Navbar"
// import homeBackground from "../../assets/images/Background-home.png";
import { Box } from "@mui/material";

export default function ProjectAndActivities() {
  return (
    <Box> 
      <Navbar />
    </Box>
  );
}
